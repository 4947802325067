import React from 'react';
import ContactForm from './ContactForm';

const Contact: React.FC = () => (
  <div className="box">
    <div className="content">
      <h3 className="title is-5 has-text-primary">Contact</h3>
      <p>Tel: 07905 590158</p>
      {/* <p>Email: hello@lavacleaners.co.uk</p> */}
      <ContactForm />
    </div>
  </div>
);

export default Contact;
