import React from 'react';

const Header: React.FC = () => (
  <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div className="container">
      <div className="navbar-start">
        <div className="navbar-brand">
          <a className="navbar-item" href="#">
            Lava Cleaners - Tel: 07905 590158
          </a>
        </div>
      </div>
    </div>
  </nav>
);

export default Header;
