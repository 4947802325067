import React from 'react';

const Title: React.FC = () => (
  <div className="content has-text-centered">
    <h1 className="title is-1 has-text-primary">Lava Cleaners</h1>
    <h3 className="title is-5 has-text-info">More free time for the things you love to do</h3>
  </div>
);

export default Title;
