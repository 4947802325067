import React from 'react';
import CookieConsent from 'react-cookie-consent';
import './App.css';
import Contact from './contact/Contact';
import Header from './layout/Header';
import Hero from './layout/Hero';
import Title from './layout/Title';
import About from './services/About';
import ServiceTypes from './services/ServiceTypes';

const App: React.FC = () => (
  <>
    <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
    <Header />
    <div className="container">
      <Hero />
      <div className="section">
        <div className="columns">
          <div className="column is-full">
            <Title />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <About />
          </div>
          <div className="column">
            <ServiceTypes />
          </div>
        </div>
        {/* <div className="columns">
          <div className="column">
            <div className="box">
              <div className="content">
                <h3 className="title is-5 has-text-primary">Testimonials</h3>
                <p>Some words from customers</p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="columns">
          <div className="column">
            <Contact />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default App;
