import React from 'react';

const items = ['Cleaners you can trust', 'Fantastic results', 'Excellent Prices'];

const About: React.FC = () => (
  <div className="box">
    <div className="content">
      <h3 className="title is-5 has-text-primary">Cleaning Services</h3>
      <p>
        Lava Cleaning provide commercial and domestic cleaning services in and around Norwich. We are a small team who
        take pride in the quality of our work and want to help you reclaim your free time enjoy your home.
      </p>
      <p>
        We can provide regular or one off cleaning for your home as well as End of Tenancy or other commerclal services.
      </p>
      <p>
        Contact us if you would like to know more about our services and how we can help.
        <ul>
          {items.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </p>
    </div>
  </div>
);

export default About;
