import React from 'react';

const ContactForm: React.FC = () => {
  return (
    <div>
      <form action="https://formspree.io/stuarttottle@hotmail.com" method="POST">
        <fieldset id="fs-frm-inputs">
          <div className="columns">
            <div className="column">
              <div className="field">
                <label htmlFor="full-name" className="label">
                  Your Name
                </label>
                <div className="control">
                  <input className="input" type="text" name="name" id="full-name" placeholder="Your Name" />
                </div>
              </div>
              <div className="field">
                <label htmlFor="full-name" className="label">
                  Email Address
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="email"
                    name="_replyto"
                    id="email-address"
                    placeholder="email@domain.tld"
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea className="textarea" name="message" id="message" placeholder="Your message"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-link" type="submit">
                Submit
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default ContactForm;
