import React from 'react';

const items = [
  'One-off, weekly or fortnightly',
  'Deep Spring Cleans',
  'General House Cleaning',
  'End of tenancy',
  'After party/builders',
  'Help for the Elderly',
  'Help for Parents'
];

const ServiceTypes: React.FC = () => (
  <div className="box">
    <div className="content">
      <h3 className="title is-5 has-text-primary">Service Types</h3>
      <ul>
        {items.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  </div>
);

export default ServiceTypes;
